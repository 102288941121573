import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"

const LogoBlack = props => {
  const data = useStaticQuery(graphql`
    query LogoBlakcQuery {
      file(absolutePath: { regex: "/ciklosport-black.svg/" }) {
        publicURL
      }
    }
  `)
  var height = props.height
  if (!height) height = 90

  const { publicURL } = data.file
  return (
    <Link to="/">
      <img src={publicURL} alt="Ciklo-Sport" style={{ maxHeight: height }} />
    </Link>
  )
}

export default LogoBlack
