import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"

const Logo = props => {
  const data = useStaticQuery(graphql`
    query LogoQuery {
      file(absolutePath: { regex: "/ciklosport.svg/" }) {
        publicURL
      }
    }
  `)
  var height = props.height
  if (!height) height = "6vh"

  const { publicURL } = data.file
  return (
    <Link style={{ width: "0 !important" }} to="/">
      <img
        src={publicURL}
        alt="Ciklo-Sport"
        style={{
          maxHeight: height,
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          filter: "drop-shadow(0px 0px 2px #000)",
          width: "30%",
        }}
      />
    </Link>
  )
}

export default Logo
