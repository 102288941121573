import React, { useContext } from "react"
import { navigate } from "gatsby"
import { AddShoppingCart, ShoppingCart } from "@material-ui/icons"
import { Badge, makeStyles, Button } from "@material-ui/core"
import BasketContext from "../contexts/basket"

const useStyles = makeStyles(theme => ({
  basketAdd: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(9),
    zIndex: 99,
    height: "70px",
    width: "70px",
    borderRadius: "50px",
    WebkitBoxShadow: "0px 0px 25px rgba(0,0,0,1.0)",
    backgroundColor: "#fff",
    color: "#000",
    transition: "all .2s ease-in-out",
    "&:hover": {
      transform: "scale(1.2)",
      color: "#000",
      backgroundColor: "#fff",
    },
  },
  basket: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    borderRadius: "50px",
    height: "70px",
    width: "70px",
    color: "#fff",
    backgroundColor: "#000",
    WebkitBoxShadow: "0px 0px 25px rgba(255,255,255,1.0)",
    transition: "all .2s ease-in-out",
    zIndex: "99",
    "&:hover": {
      transform: "scale(1.2)",
      color: "#fff",
      backgroundColor: "#000",
    },
  },
}))

export const AddToBasket = props => {
  const classes = useStyles()
  const basket = useContext(BasketContext)

  const add = () => {
    basket.addItem(props.item)
  }

  return (
    <Button
      onClick={add}
      className={classes.basketAdd}
      title="Dodaj u košaricu"
    >
      <AddShoppingCart />
    </Button>
  )
}

export const BasketStatus = props => {
  const classes = useStyles()
  const basket = useContext(BasketContext)
  const handleBasketClick = () => navigate("/kosarica")
  const count = basket.items.reduce((sum, c) => sum + c.quantity, 0)

  //if (document.location.pathname.startsWith("/kosarica")) return null // does not work

  return (
    <Button
      onClick={handleBasketClick}
      className={classes.basket}
      title="Košarica"
    >
      <Badge badgeContent={count} color="error" invisible={count === 0}>
        <ShoppingCart />
      </Badge>
    </Button>
  )
}
