import React, { useState, useMemo, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { makeStyles } from "@material-ui/core/styles"
import {
  Toolbar,
  Typography,
  IconButton,
  SwipeableDrawer,
  useScrollTrigger,
  Slide,
  AppBar,
} from "@material-ui/core"
import {
  Menu,
  Search,
  Facebook,
  Instagram,
  DirectionsBike,
  LocationOn,
  Info,
  Build,
} from "@material-ui/icons"
import Logo from "./logo"
import SideNav from "./side-nav"
import SearchBox from "./search"

const useStyles = makeStyles(theme => ({
  toolbar: {
    display: "block",
    padding: 0,
    width: "100vw",
  },
  toolbarMenu: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    width: "100vw",
    paddingLeft: "1%",
    paddingRight: "1%",
    justifyContent: "space-between",
    [theme.breakpoints.up("md")]: {
      marginLeft: "-10px",
    },
  },
  menuButton: {
    textTransform: "uppercase",
    fontSize: "large",
    margin: 0,
    transition: "all .1s ease-in-out",
    filter: "drop-shadow(0px 0px 1px #000)",
    "&:hover": {
      backgroundColor: "rgba(255,255,255,.1)",
      borderRadius: "2px",
    },
    "& > span": { overflow: "visible !important" },
  },
  toolbarSecondary: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    justifyContent: "space-between",
    backgroundColor: "rgba(0,0,0,0)",
    color: "#fff",
    zIndex: 101,
    width: "100vw",
    marginBottom: theme.spacing(0),
    minHeight: "32px",
    paddingRight: "1%",
    paddingLeft: "1%",
    [theme.breakpoints.up("md")]: {
      marginLeft: "-10px",
    },
  },
  toolbarSecDivs: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    alignItems: "center",
    textShadow: "0px 0px 2px #000",
    "& > p": {
      display: "none",
      margin: 0,
      textTransform: "uppercase",
      fontWeight: 900,
      fontSize: "1.4vh",
      textWrap: "nowrap",
      [theme.breakpoints.up("md")]: {
        display: "contents",
      },
    },
  },
  toolbarSecDivP: {
    textDecoration: "none",
    display: "none",
    margin: 0,
    textTransform: "uppercase",
    fontWeight: 900,
    fontSize: "1.4vh",
    textWrap: "nowrap",
    color: "#fff",
    [theme.breakpoints.up("md")]: {
      display: "contents",
      cursor: "default",
    },
  },
  navIcons: {
    fontSize: "2.5vh !important",
    [theme.breakpoints.up("md")]: {
      fontSize: "1vw",
    },
  },
  toolbarLink: {
    padding: theme.spacing(0),
    flexShrink: 0,
    fontSize: "0.5vw",
  },
  drawerPaper: {
    backgroundColor: "#fff",
    color: "#000",
    boxShadow: "0px 0px 100px rgba(0,0,0,.5)",
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
    borderRight: `1px solid ${theme.palette.divider}`,
    borderColor: "#252525 !important",
    "&::-webkit-scrollbar": {
      width: 0,
    },
  },
  drawerPaper1: {
    backgroundColor: "#fff",
    boxShadow: "0px 0px 100px rgba(255,255,255,.5)",
    borderLeft: `1px solid ${theme.palette.divider}`,
    borderColor: "#252525",
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,
  },
  facebook: {
    opacity: 0.9,
    transition: "all .2s ease-in-out",
    padding: 0,
    margin: "5px",
    filter: "drop-shadow(0px 0px 1px #000)",
    "&:hover": {
      opacity: 1,
      transform: "scale(1.2)",
      color: "#3578E5",
      filter: "none",
    },
  },
  instagram: {
    opacity: 0.9,
    transition: "all .2s ease-in-out",
    padding: 0,
    margin: "5px",
    filter: "drop-shadow(0px 0px 1px #000)",
    "&:hover": {
      opacity: 1,
      transform: "scale(1.2)",
      color: "#C13584",
      filter: "none",
    },
  },
  twitter: {
    opacity: 0.9,
    transition: "all .2s ease-in-out",
    padding: 0,
    margin: "5px",
    filter: "drop-shadow(0px 0px 1px #000)",
    "&:hover": {
      opacity: 1,
      transform: "scale(1.2)",
      color: "#00acee",
      filter: "none",
    },
  },
  youtube: {
    opacity: 0.9,
    transition: "all .2s ease-in-out",
    padding: 0,
    margin: "5px",
    filter: "drop-shadow(0px 0px 1px #000)",
    "&:hover": {
      opacity: 1,
      transform: "scale(1.2)",
      color: "#FF0000",
      filter: "none",
    },
  },
  strava: {
    opacity: 0.9,
    transition: "all .2s ease-in-out",
    padding: 0,
    margin: "5px",
    filter: "drop-shadow(0px 0px 1px #000)",
    "&:hover": {
      opacity: 1,
      transform: "scale(1.2)",
      color: "#F77737",
      filter: "none",
    },
  },
  location: {
    opacity: 0.9,
    transition: "all .2s ease-in-out",
    padding: 0,
    margin: "5px",
    filter: "drop-shadow(0px 0px 1px #000)",
    "&:hover": {
      opacity: 1,
      transform: "scale(1.2)",
      color: "#FD1D1D",
    },
  },
  menuIcons: {
    fontSize: "8vw",
    [theme.breakpoints.up("md")]: {
      fontSize: "2vw",
    },
  },
  menuIconText: {
    [theme.breakpoints.up("md")]: {
      marginLeft: 5,
      fontSize: "0.8vw",
      display: "block",
      fontWeight: "800",
    },
    display: "none",
  },
  searchIconText: {
    [theme.breakpoints.up("md")]: {
      marginRight: 5,
      fontSize: "0.8vw",
      display: "block",
      fontWeight: "800",
    },
    display: "none",
  },
  links: {
    opacity: 0.9,
    transition: "all .2s ease-in-out",
    padding: 0,
    margin: "5px",
    filter: "drop-shadow(0px 0px 1px #000)",
    "&:hover": {
      opacity: 1,
      transform: "scale(1.2)",
      color: "#03dbfc",
      filter: "none",
    },
  },
  appBarTransparent: {
    backgroundColor: "rgba(0,0,0,0)",
    minHeight: "60px",
    width: "100%",
    color: "#fff",
  },
  appBarSolid: {
    backgroundColor: "rgba(0,0,0)",
    minHeight: "60px",
    width: "100%",
    color: "#fff",
  },
}))

export default function Header() {
  const d = useStaticQuery(
    graphql`
      query Header {
        site {
          siteMetadata {
            headerLinks {
              link
              text
            }
            treeLevels
          }
        }
        allMarkdownRemark(
          filter: { frontmatter: { price: { eq: null } } }
          sort: { order: ASC, fields: frontmatter___order }
        ) {
          nodes {
            fields {
              slug
            }
            frontmatter {
              title
              order
            }
          }
        }
      }
    `
  )

  const navCalc = useMemo(() => {
    const nav = {
      1: [],
      2: [],
      3: [],
      4: [],
      5: [],
      6: [],
      7: [],
      8: [],
      9: [],
    }
    for (let i = 0; i < d.allMarkdownRemark.nodes.length; i++) {
      const page = d.allMarkdownRemark.nodes[i]

      const node = {
        text: page.frontmatter.title,
        link: page.fields.slug,
        order: page.frontmatter.order,
        parts: page.fields.slug
          .substring(1, page.fields.slug.length - 1)
          .split("/"),
      }

      if (node.parts.length === undefined) continue // root page

      node.parentLink = `/${node.parts
        .slice(0, node.parts.length - 1)
        .join("/")}/`
      nav[node.parts.length].push(node)
    }

    const processLevel = level => {
      for (let index = 0; index < nav[level].length; index++) {
        const element = nav[level][index]
        element.children = nav[level + 1].filter(
          e => e.parentLink === element.link
        )
      }
      if (level > 1) processLevel(level - 1)
    }
    processLevel(d.site.siteMetadata.treeLevels - 1)
    return nav[1]
  }, [d])

  const [navBackground, setNavBackground] = useState("appBarTransparent")
  const navRef = React.useRef()
  navRef.current = navBackground
  useEffect(() => {
    const handleScroll = () => {
      const show = window.scrollY > 100
      if (show) {
        setNavBackground("appBarSolid")
      } else {
        setNavBackground("appBarTransparent")
      }
    }
    document.addEventListener("scroll", handleScroll)
    return () => {
      document.removeEventListener("scroll", handleScroll)
    }
  }, [])
  const trigger = useScrollTrigger({ threshold: 10 })
  const [navigationOpen, setNavigationOpen] = useState(false)
  const [searchOpen, setSearchOpen] = useState(false)
  const toggleNavigation = () => setNavigationOpen(p => !p)
  const toggleSearch = () => setSearchOpen(p => !p)
  const classes = useStyles()

  return (
    <>
      {/* <div>
        {d.site.siteMetadata.headerLinks.map(l => (
            <Link
              component={Glink}
              to={l.link}
              color="inherit"
              noWrap
              key={l.link}
              variant="button"
              className={classes.toolbarLink}
            >
              {l.text}
            </Link>
          ))}</div> */}

      {/* Navigation */}
      <Slide in={!trigger}>
        <AppBar
          position="fixed"
          elevation={0}
          className={classes[navRef.current]}
        >
          <Toolbar className={classes.toolbar}>
            <div className={classes.toolbarSecondary}>
              <div className={classes.toolbarSecDivs}>
                <a
                  href="https://g.page/CikloSport?share"
                  rel="noreferrer"
                  target="_blank"
                  className={classes.toolbarSecDivP}
                >
                  <p>Lokacija:</p>
                </a>
                <IconButton
                  color="inherit"
                  className={classes.location}
                  edge="start"
                  href="https://g.page/CikloSport?share"
                  target="_blank"
                  rel="noreferrer"
                >
                  <LocationOn className={classes.navIcons} />
                </IconButton>
                <a
                  href="/servis/"
                  target="_blank"
                  rel="noreferrer"
                  className={classes.toolbarSecDivP}
                >
                  <p>Servis:</p>
                </a>
                <IconButton
                  color="inherit"
                  className={classes.links}
                  edge="start"
                  href="/servis/"
                >
                  <Build className={classes.navIcons} />
                </IconButton>
                <a
                  href="/informacije/"
                  target="_blank"
                  rel="noreferrer"
                  className={classes.toolbarSecDivP}
                >
                  <p>Informacije:</p>
                </a>
                <IconButton
                  color="inherit"
                  className={classes.links}
                  edge="start"
                  href="/informacije/"
                >
                  <Info className={classes.navIcons} />
                </IconButton>
              </div>
              <div className={classes.toolbarSecDivs}>
                <p style={{ cursor: "default" }}>Zaprati nas:</p>
                <IconButton
                  className={classes.facebook}
                  color="inherit"
                  edge="start"
                  href="https://www.facebook.com/ciklosport"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Facebook className={classes.navIcons} />
                </IconButton>
                <IconButton
                  className={classes.instagram}
                  color="inherit"
                  edge="start"
                  href="https://www.instagram.com/ciklosport/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Instagram className={classes.navIcons} />
                </IconButton>
                <IconButton
                  className={classes.strava}
                  color="inherit"
                  edge="start"
                  href="https://www.strava.com/clubs/ciklosport"
                  target="_blank"
                  rel="noreferrer"
                >
                  <DirectionsBike className={classes.navIcons} />
                </IconButton>
              </div>
            </div>
            <div className={classes.toolbarMenu}>
              <IconButton
                color="inherit"
                edge="start"
                onClick={toggleNavigation}
                className={classes.menuButton}
              >
                <Menu className={classes.menuIcons} />
                <Typography className={classes.menuIconText}>
                  Izbornik
                </Typography>
              </IconButton>
              <Logo />
              <IconButton
                color="inherit"
                edge="start"
                onClick={toggleSearch}
                className={classes.menuButton}
              >
                <Typography className={classes.searchIconText}>
                  Pretraži
                </Typography>
                <Search className={classes.menuIcons} />
              </IconButton>
            </div>
          </Toolbar>
        </AppBar>
      </Slide>
      <SwipeableDrawer
        anchor={"left"}
        open={navigationOpen}
        onClose={toggleNavigation}
        onOpen={toggleNavigation}
        classes={{ paper: classes.drawerPaper }}
        ModalProps={{
          keepMounted: true,
        }}
      >
        <SideNav data={navCalc} />
      </SwipeableDrawer>
      {/* Search */}
      <SwipeableDrawer
        anchor={"right"}
        open={searchOpen}
        onClose={toggleSearch}
        onOpen={toggleSearch}
        classes={{ paper: classes.drawerPaper1 }}
        ModalProps={{
          keepMounted: true,
        }}
      >
        <SearchBox />
      </SwipeableDrawer>
    </>
  )
}
