import React from "react"
import { Container, makeStyles } from "@material-ui/core"
import Header from "./header"
import Footer from "./footer"
import { BasketStatus } from "./basket-buttons"

const useStyles = makeStyles(theme => ({
  divider: {
    marginTop: 1,
  },
  main: {
    paddingLeft: 0,
    paddingRight: 0,
    maxWidth: "100vw",
  },
}))

const Layout = props => {
  const { children } = props
  const classes = useStyles()

  return (
    <>
      <Container maxWidth="xl" className={classes.main}>
        <main className>
          <Header />
          {children}
        </main>
        <footer>
          <Footer />
        </footer>
      </Container>
      <BasketStatus />
    </>
  )
}

export default Layout
