import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"

const Logo1 = props => {
  const data = useStaticQuery(graphql`
    query Logo1Query {
      file(absolutePath: { regex: "/ciklosport.svg/" }) {
        publicURL
      }
    }
  `)
  var height = props.height
  if (!height) height = 90

  const { publicURL } = data.file
  return (
    <Link to="/">
      <img
        src={publicURL}
        alt="Ciklo-Sport"
        style={{ maxHeight: height, marginTop: 2 }}
      />
    </Link>
  )
}

export default Logo1
