import React, { useState, useMemo } from "react"
import { Link as Glink } from "gatsby"
import { makeStyles } from "@material-ui/core/styles"
import { Add, Remove } from "@material-ui/icons"
import { TreeView, TreeItem } from "@material-ui/lab"
import { Link } from "@material-ui/core"
import LogoBlack from "./logo-black"

const useStyles = makeStyles(theme => ({
  "@global": {
    ".MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label": {
      backgroundColor: "white",
    },
    ".MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label:hover, .MuiTreeItem-root.Mui-selected:focus > .MuiTreeItem-content .MuiTreeItem-label": {
      backgroundColor: "#fff",
    },
  },
  root: {
    height: 110,
    flexGrow: 1,
    maxWidth: 450,
    minWidth: 350,
    WebkitTapHighlightColor: "rgba(0,0,0,0)",
    // paddingTop: theme.spacing(2),
    // paddingLeft: theme.spacing(2),
    // paddingRight: theme.spacing(2),
    // "&::-webkit-scrollbar": {width: 0},
    [theme.breakpoints.up("md")]: {
      minWidth: 450,
      maxWidth: 550,
    },
  },
  nav: {
    paddingTop: "2vh",
    paddingBottom: "2vh",
    paddingRight: "2vh",
    paddingLeft: "2vh",
    opacity: 1,
    transition: "all .3s ease-in-out",
    "& > div": {
      "& > div": {
        paddingTop: "1vh",
        paddingBottom: "1vh",
        "&:hover": { backgroundColor: "#fff" },
      },
    },
    "& > ul": {
      "& > div": {
        "& > div": {
          "& > li": {
            "& > div": {
              "& > div": {
                paddingTop: "1.5vh",
                paddingBottom: "1.5vh",
                "& > a": { fontSize: "2.5vh" },
              },
            },
          },
        },
      },
    },
  },
  toolbarLink: {
    fontSize: "3vh",
    fontFamily: "Roboto",
    fontWeight: 900,
    textDecoration: "none !important",
    transition: "all .2s ease-in-out",
  },
  plusButton: {
    fontSize: "25px !important",
  },
  minusButton: {
    fontSize: "25px !important",
  },
  logo: {
    display: "block",
    textAlign: "center",
    marginTop: "5%",
  },
}))

export default function SideNav(props) {
  const classes = useStyles()
  const defaultExpanded = useMemo(() => {
    const paths = []
    var path = decodeURIComponent(window.location.pathname)
    if (path === "/") return paths

    var parts = path.substring(1, path.length - 1).split("/")
    for (let i = parts.length; i > 0; i--) {
      const partial = `/${parts.slice(0, i).join("/")}/`
      paths.push(partial)
    }
    return paths
  }, [])
  const [expanded, setExpanded] = useState(defaultExpanded)
  const [selected, setSelected] = useState([
    decodeURIComponent(window.location.pathname),
  ])
  const handleToggle = (event, nodeIds) => setExpanded(nodeIds)

  const handleSelect = (event, nodeIds) => setSelected(nodeIds)

  const renderTree = (node, padd) => {
    return (
      <TreeItem
        key={node.link}
        nodeId={node.link}
        className={(padd && classes.nav) || undefined}
        label={
          <Link
            component={Glink}
            to={node.link}
            color="inherit"
            noWrap
            key={node.link}
            className={classes.toolbarLink}
          >
            {node.text}
          </Link>
        }
      >
        {Array.isArray(node.children)
          ? node.children.length > 0
            ? node.children.map(node => renderTree(node, false))
            : null
          : null}
      </TreeItem>
    )
  }
  return (
    <TreeView
      sx={{ "&::-webkit-scrollbar": { width: "0px" } }}
      className={classes.root}
      defaultCollapseIcon={<Remove className={classes.minusButton} />}
      defaultExpandIcon={<Add className={classes.plusButton} />}
      expanded={expanded}
      selected={selected}
      onNodeToggle={handleToggle}
      onNodeSelect={handleSelect}
    >
      {Array.isArray(props.data) &&
        props.data.map(node => renderTree(node, true))}
      <span className={classes.logo}>
        <LogoBlack />
      </span>
    </TreeView>
  )
}
