import React, { useState } from "react"
import algoliasearch from "algoliasearch/lite"
import { Link as Glink } from "gatsby"
import { TextField, makeStyles, Link, List, ListItem } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
  input: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  search: {
    textAlign: "right",
    display: "block",
    fontSize: "12px",
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(2),
    minWidth: 300,
    [theme.breakpoints.up("md")]: {
      minWidth: 450,
      maxWidth: 550,
    },
  },
  searchLogo: {
    height: "14px",
  },
  result: {
    "& em": {
      color: "#000",
    },
  },
  results: {},
}))

const algoliaClient = algoliasearch(
  process.env.GATSBY_ALGOLIA_APP_ID,
  process.env.GATSBY_ALGOLIA_SEARCH_KEY
)
const algoliaIndex = algoliaClient.initIndex("Pages")

export default function SearchBox() {
  const classes = useStyles()
  const [term, setTerm] = useState()
  const [results, setResults] = useState([])

  const search = e => {
    e.preventDefault()

    algoliaIndex
      .search(term)
      .then(r => setResults(r.hits))
      .catch(e => console.log(e))
  }

  return (
    <>
      <form onSubmit={search}>
        <div className={classes.input}>
          <TextField
            label="Pretraži..."
            variant="outlined"
            fullWidth
            onChange={e => setTerm(e.target.value)}
          />
        </div>
      </form>
      <List>
        {results.map(r => (
          <ListItem>
            <Link
              className={classes.result}
              component={Glink}
              to={r.slug}
              color="inherit"
              noWrap
              key={r.slug}
              variant="button"
              dangerouslySetInnerHTML={{
                __html: r._highlightResult.title.value,
              }}
            ></Link>
          </ListItem>
        ))}
      </List>
      <div className={classes.search}>
        Powered by&nbsp;
        <a href="http://www.algolia.com">
          <img
            className={classes.searchLogo}
            src="https://www.algolia.com/assets/algolia128x40.png"
            alt="Algolia logo"
          />
        </a>
      </div>
    </>
  )
}
